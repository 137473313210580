import * as dompack from 'dompack';
import { WWBasicSite } from '@mod-ww/webdesigns/template/ww';

import '@mod-nope/embeddedobjects/logoslider/logoslider';

import './lycurgus.scss';

dompack.onDomReady(() => {
  new WWBasicSite({});
});
